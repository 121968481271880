@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - var(--topbarHeightDesktop));
  z-index: 1;
  position: relative;
  overflow: hidden;
}

.backgroundVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: -89vw center;
  z-index: -2;

  @media (--viewportMedium) {
    object-position: center -10vh;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  z-index: -1;
}

.centerSection {
  position: absolute;
  max-width: 716px;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 14px;
  bottom: 20vh;

  @media (--viewportMedium) {
    bottom: unset
  }
}

.title {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 31px;
  color: var(--colorWhite);
  padding: 0;
  margin: 0;
  text-transform: uppercase;

  @media (--viewportMedium) {
    font-size: 64px;
    line-height: 59px;
  }
}

.info {
  font-family: 'Neue Haas Grotesk Display Pro';
  color: var(--colorWhite);
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  margin: 0;
  padding: 0 16px;

  @media (--viewportMedium) {
    font-size: 24px;
    line-height: 34px;
    padding: 0;
  }
}

.button {
  font-family: 'Neue Haas Grotesk Display Pro';
  background-color: transparent;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
  color: var(--marketplaceColor);
  border: 1px solid var(--marketplaceColor);
  padding: 12px 38px;
  margin-top: 17px;
  border-radius: 0;
  width: fit-content;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;

  &:hover,
  &:focus {
    color: var(--colorWhite);
  }
}

.button:hover svg path,
.button:focus svg path {
  fill: var(--colorWhite);
}

.scrollingTextWrapper {
  width: 100%;
  overflow: hidden;
  position: fixed;
  bottom: 35px;
  left: 0;
}

.scrollingTextContainer {
  display: flex;
  width: max-content;
  animation: scrollContinuously 15s linear infinite;
}

.scrollingText {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 24px;
  line-height: 59px;
  text-transform: uppercase;
  color: var(--colorWhite);
  padding: 0 5rem;
  margin: 0;
  white-space: nowrap;
  display: inline-block;

  @media (--viewportMedium) {
    font-size: 64px;
    line-height: 59px;
  }
}

@keyframes scrollContinuously {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-50%);
  }
}

.scrollLayer {
  align-items: center !important;
}

.modalContainer {
  height: 360px !important;
  margin: 0 !important;
  flex-basis: 800px !important;
  padding: 30px !important;
  border: 0 !important;
  border-radius: 10px !important;
}

.modalContent {
  height: 100%;

  @media (--viewportMedium) {
    height: unset;
  }
}

.closeIcon {
  aspect-ratio: 1/1;
}