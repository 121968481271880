@import '../../../styles/customMediaQueries.css';

.root {
    display: flex;
    gap: 30px;
    flex-direction: column-reverse;
    height: 100%;
    justify-content: space-between;

    @media (--viewportMedium) {
        align-items: stretch;
        flex-direction: row;
        height: unset;
        justify-content: unset;
    }
}

.textBig {
    font-family: 'Neue Haas Grotesk Display Pro';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
    text-transform: uppercase;
    text-align: center;
    padding: 0;

    @media (--viewportMedium) {
        font-size: 32px;
        line-height: 38px;
        margin: 48px 0;
    }
}

.image {
    width: 100%;

    @media (--viewportMedium) {
        width: fit-content;
    }
}

.text {
    text-align: center;
    padding: 0;
    margin: 32px 0;
}

.wrapper {
    width: 100%;
    height: 50%;

    @media (--viewportMedium) {
        height: unset;
    }
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.label {
    font-family: "Neue Haas Grotesk Display Pro";
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
}

.fieldWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.field {
    @media (--viewportMedium) {
        display: flex;
        gap: 24px;

    }
}

.inputClass {
    font-weight: 300;
    box-shadow: none;
    display: flex;

    &::placeholder {
        font-family: 'Neue Haas Grotesk Display Pro';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
    }

    &:focus,
    &:hover {
        box-shadow: none;
    }

}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.submitButton {
    font-family: 'Neue Haas Grotesk Display Pro';
    min-height: 45px;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    border-radius: 0;
}

.submitButton:disabled {
    background-color: var(--colorBlack);
    color: var(--marketplaceColor);
}